import ClassLogger from 'ClassLogger';

class Slider {
    getClassName () { return 'Slider'; }
    constructor (commonMethods) {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.init();
    }

    init () {
        const sliders = document.querySelectorAll('.c-slider');
        if (sliders.length > 0) {
            sliders.forEach((slider) => {
                this.handleSlider(slider);
            });
        }
    }

    handleSlider (slider) {
        if (slider.classList.contains('is-initialized')) {
            return;
        }

        const sliderTrack = slider.querySelector('.c-slider__track');
        if (!sliderTrack) {
            this.logger.log('No slider track found');
        }

        sliderTrack.dataset.currentslide = 1;

        slider.classList.add('is-initialized');

        const sliderItems = sliderTrack.querySelectorAll('.c-slider__item');
        sliderTrack.dataset.total = sliderItems.length;

        // Add slideidx attributes if they do not yet exist
        sliderItems.forEach((slideNode, idx) => {
            slideNode.dataset.slideidx = slideNode.dataset.slideidx || (idx + 1);
        });

        const sliderOptions = slider.dataset.slideroptions;
        let arrowNav = false;
        if (sliderOptions) {
            const sliderOptionsObject = JSON.parse(sliderOptions);
            arrowNav = sliderOptionsObject.arrowNav ? sliderOptionsObject.arrowNav : false;
            const dots = sliderOptionsObject.dots ? sliderOptionsObject.dots : false;
            const autoplay = sliderOptionsObject.autoplay ? sliderOptionsObject.autoplay : false;
            const thumbnails = sliderOptionsObject.thumbnails ? sliderOptionsObject.thumbnails : false;
            const autoplayDuration = sliderOptionsObject.autoplayDuration ? sliderOptionsObject.autoplayDuration : 3000;
            if (sliderItems.length > 1) {
                let resetAutoplay = () => {};

                if (autoplay) {
                    let isHovered = false;
                    let interval = null;

                    // when navigating (arrows/dots) we want to reset the interval
                    resetAutoplay = () => {
                        clearInterval(interval);
                        interval = setInterval(() => {
                            if (isHovered === true) {
                            // wait until mouseout while hovered
                                clearInterval(interval);
                                slider.addEventListener('mouseout', () => {
                                    this.goToNext(sliderTrack);
                                }, { once: true });
                                return;
                            }
                            this.goToNext(sliderTrack);
                        }, autoplayDuration);
                    };
                    resetAutoplay();

                    slider.addEventListener('mouseover', () => {
                        isHovered = true;
                    });

                    slider.addEventListener('mouseout', () => {
                        isHovered = false;
                    });
                }
                if (dots) {
                    this.initializeDots(slider, sliderTrack, sliderItems, resetAutoplay);
                }

                if (arrowNav) {
                    this.arrowNav(slider, sliderTrack, resetAutoplay);
                }

                if (thumbnails) {
                    this.handleThumbnails(slider, sliderTrack, sliderItems);
                }
            }
        }

        // initial disable state
        this.checkSlidePosition(slider, sliderTrack);
        this.handleChangedIdx(slider, sliderTrack, 1);

        // on scroll updates
        sliderTrack.addEventListener('scroll', () => {
            this.commonMethods.debounce(() => {
                this.checkSlidePosition(slider, sliderTrack);
            }, 25)();
        });

        this.dragHandler(sliderTrack);

        const observer = new IntersectionObserver((entries) => {
            this.handleSlideIntersect(slider, sliderTrack, entries);
        }, {
            root: slider,
            rootMargin: '0px',
            threshold: 0.20,
        });

        sliderItems.forEach((slide) => {
            observer.observe(slide);
        });
    }

    handleThumbnails (slider, sliderTrack) {
        const thumbWrapper = slider.querySelector('.c-slider__thumbs');
        if (!thumbWrapper) {
            this.logger.warn('No thumbnails found', { sliderTrack });
            return;
        }

        const thumbItems = thumbWrapper.querySelectorAll('li');
        thumbItems.forEach((item) => {
            item.addEventListener('click', (e) => {
                const targetSlideIdx = item.dataset.slidergoto;
                this.goToSlide(sliderTrack, targetSlideIdx);
            });
        });
    }

    arrowNav (slider, sliderTrack, resetAutoplay) {
        const self = this;
        const controlPrev = this.commonMethods.markupToElement(`
            <button class="c-slider__control c-slider__control--prev">
                ${this.commonMethods.getSvgIcon('chevron_left')}
            </button>
        `);
        const controlNext = this.commonMethods.markupToElement(`
            <button class="c-slider__control c-slider__control--next">
                ${this.commonMethods.getSvgIcon('chevron_right')}
            </button>
        `);

        slider.append(controlPrev);
        slider.append(controlNext);

        controlPrev.addEventListener('click', () => { self.goToPrev(sliderTrack); resetAutoplay(); });
        controlNext.addEventListener('click', () => { self.goToNext(sliderTrack); resetAutoplay(); });
    }

    initializeDots (slider, sliderTrack, sliderItems, resetAutoplay) {
        const dotWrapper = slider.querySelector('.c-slider__dots');

        if (!dotWrapper) {
            this.logger.warn('No slider dots found', { sliderTrack });
            return;
        }

        // clear out wrapper, just in case Turbolink stuff has ruined it. It was a thing!
        dotWrapper.innerHTML = '';

        if (sliderItems.length > 1 && dotWrapper !== null) {
            sliderItems.forEach((slide, index) => {
                index = index + 1;
                let activeClass = '';
                if (index === 1) {
                    activeClass = 'is-active';
                }

                const dotNode = this.commonMethods.markupToElement(`
                    <li class="c-slider__dot ${activeClass}" data-dotindex="${index}">
                        <button data-slidergoto="${index}">
                            <span class="u-sr-only">zu Slide ${index}</span>
                        </button>
                    </li>
                `);
                dotNode.querySelector('button').addEventListener('click', (e) => {
                    const targetSlideIdx = e.target.dataset.slidergoto;
                    this.goToSlide(sliderTrack, targetSlideIdx);
                    resetAutoplay();
                });
                dotWrapper.append(dotNode);
            });
        }
    }

    goToSlide (sliderTrack, slideIdx) {
        const slideToShow = sliderTrack.querySelector(`[data-slideidx="${slideIdx}"]`);
        if (slideToShow) {
            sliderTrack.scrollLeft = slideToShow.offsetLeft - parseInt(getComputedStyle(sliderTrack).paddingRight);
        } else {
            this.logger.warn('Slide to show not found', { slideIdx, sliderTrack });
        }
    }

    goToNext (sliderTrack) {
        const oldIdx = parseInt(sliderTrack.dataset.currentslide);

        if (!isNaN(oldIdx)) {
            let newIdx = oldIdx + 1;
            const slideItemCount = sliderTrack.querySelectorAll('.c-slider__item').length;

            if (newIdx > slideItemCount) {
                newIdx = 1;
            }

            this.goToSlide(sliderTrack, newIdx);
        }
    }

    goToPrev (sliderTrack) {
        const oldIdx = parseInt(sliderTrack.dataset.currentslide);

        if (!isNaN(oldIdx)) {
            let newIdx = oldIdx - 1;

            if (newIdx <= 0) {
                newIdx = sliderTrack.querySelectorAll('.c-slider__item').length;
            } else {
                const activeItems = sliderTrack.querySelectorAll('.c-slider__item.is-active');
                if (activeItems.length > 0) {
                    newIdx = parseInt(activeItems[0].dataset.slideidx) - 1;
                }
                // newIdx could result in 0. We need to then add 1 to goto first slide
                if (newIdx === 0) {
                    newIdx++;
                }
            }

            this.goToSlide(sliderTrack, newIdx);
        }
    }

    dragHandler (sliderTrack) {
        let isDown = false;
        let isMoving = false;
        let startX;
        let scrollLeft = 0;
        const mouseUpHandler = (e) => {
            e.preventDefault();
            isDown = false;
            sliderTrack.classList.remove('has-mousedown');
        };

        sliderTrack.addEventListener('mousedown', (e) => {
            e.preventDefault();
            isDown = true;
            // resets isMoving so clickevent can be triggered again.
            isMoving = false;
            sliderTrack.classList.add('has-mousedown');
            startX = e.pageX - sliderTrack.offsetLeft;
            scrollLeft = sliderTrack.scrollLeft;
        });

        sliderTrack.addEventListener('mouseleave', mouseUpHandler);

        sliderTrack.addEventListener('mouseup', mouseUpHandler);

        sliderTrack.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            isMoving = true;
            e.preventDefault();
            const x = e.pageX - sliderTrack.offsetLeft;
            const walk = (x - startX) * 1.5;
            sliderTrack.scrollLeft = scrollLeft - walk;
        });

        sliderTrack.addEventListener('click', e => {
            if (isMoving) {
                e.preventDefault();
                return false;
            }
            const item = e.target.closest('.c-slider__item');
            if (item) {
                const rect = item.getBoundingClientRect();
                if (
                    // left
                    rect.left + (rect.width / 2) > 0 &&
                    // right
                    rect.left + (rect.width / 2) < (window.innerWidth || document.documentElement.clientWidth)
                ) {
                    // 50% or more visible
                } else {
                    // less than 50% visible
                    e.preventDefault();
                    if (rect.x < 0) {
                        sliderTrack.scrollLeft -= item.offsetWidth;
                    } else {
                        sliderTrack.scrollLeft += item.offsetWidth;
                    }
                    return false;
                }
            }
        });
    }

    checkSlidePosition (slider, sliderTrack) {
        const hasNextSlide = this.hasNextSlide(sliderTrack);
        const hasPrevious = sliderTrack.scrollLeft > 0;

        // Those classes are purely used for the left/right GRADIENTS to dissapear asap
        if (hasPrevious === false) {
            slider.classList.add('has-no-prev');
        } else {
            slider.classList.remove('has-no-prev');
        }

        if (hasNextSlide === false) {
            slider.classList.add('has-no-next');
        } else {
            slider.classList.remove('has-no-next');
        }

        // Check if IDX changed
        let newActiveSlideIdx = 1;
        const activeItems = sliderTrack.querySelectorAll('.c-slider__item.is-active');
        if (activeItems.length > 0) {
            newActiveSlideIdx = parseInt(activeItems[0].dataset.slideidx);
            if (hasNextSlide === false) {
                // if we have NO next slide, we must show the last idx as count
                newActiveSlideIdx = parseInt(activeItems[activeItems.length - 1].dataset.slideidx);
            }
        }

        // if IDX changed...
        if (parseInt(sliderTrack.dataset.currentslide) !== newActiveSlideIdx) {
            // handle counter, arrows, dots
            this.handleChangedIdx(slider, sliderTrack, newActiveSlideIdx);
        }
    }

    handleChangedIdx (slider, sliderTrack, newActiveSlideIdx) {
        sliderTrack.dataset.currentslide = newActiveSlideIdx;

        // arrowNav
        // Update disabled state of control next/prev buttons
        const controlPrev = slider.querySelector('.c-slider__control--prev');
        const controlNext = slider.querySelector('.c-slider__control--next');
        if (controlPrev && controlNext) {
            if (newActiveSlideIdx === parseInt(sliderTrack.dataset.total)) {
                controlNext.classList.add('is-disabled');
            } else {
                controlNext.classList.remove('is-disabled');
            }

            if (newActiveSlideIdx === 1 || slider.classList.contains('has-no-prev')) {
                controlPrev.classList.add('is-disabled');
            } else {
                controlPrev.classList.remove('is-disabled');
            }
        }

        // Update dot navigation
        const dotWrapper = slider.querySelector('.c-slider__dots');
        if (dotWrapper) {
            // Handline active states of dot navigation
            const dots = dotWrapper.querySelectorAll('.c-slider__dot.is-active');
            dots.forEach(item => {
                item.classList.remove('is-active');
            });

            const item = dotWrapper.querySelector(`.c-slider__dot[data-dotindex="${newActiveSlideIdx}"]`);
            if (item) {
                item.classList.add('is-active');
            }
        }

        // Update Thumb navigation
        const thumbWrapper = slider.querySelector('.c-slider__thumbs');
        if (thumbWrapper) {
            // Handline active states of thumb navigation
            this.logger.log('Updating Thumbnail navigation');
            const thumbs = thumbWrapper.querySelectorAll('.c-slider__thumbnail.is-active');
            thumbs.forEach(item => {
                item.classList.remove('is-active');
            });

            const item = thumbWrapper.querySelector(`.c-slider__thumbnail[data-thumbindex="${newActiveSlideIdx}"]`);
            if (item) {
                item.classList.add('is-active');
            }
        }
    }

    hasNextSlide (sliderTrack) {
        /**
         * We need to always round up (ceil) the value of scrollLeft because in some use cases
         * the value had a decimal that ended up less than the width of the sliderTrack.
         * CS 2022-11-10: Also added extra 1px
         */
        const hasNextSlide = sliderTrack.offsetWidth + Math.ceil(sliderTrack.scrollLeft) + 1 < sliderTrack.scrollWidth;
        // this.logger.log('has next slide', hasNextSlide);
        return hasNextSlide;
    }

    handleSlideIntersect (slider, sliderTrack, entries) {
        const intersectingTargets = [];

        entries.forEach((entry) => {
            if (entry.isIntersecting === true) {
                intersectingTargets.push(entry.target);
            } else {
                entry.target.classList.remove('is-active');
            }
        });

        intersectingTargets.forEach(entryTarget => {
            entryTarget.classList.add('is-active');
        });

        this.checkSlidePosition(slider, sliderTrack);
    }
}

export default Slider;
