'use strict';

import CommonMethods from './components/CommonMethods';
import Slider from './components/Slider';
import ScrollHeader from './components/ScrollHeader';
import MobileMenu from './components/MobileMenu';
import 'a11y-dialog';
import TabNav from './components/Tabnav';
import Tooltip from './components/Tooltip';
import ConditionalDataToggle from './components/ConditionalDataToggle';
import FormHandler from './components/FormHandler';
import Dialog from './components/Dialog';
import BookingHandler from './components/Bookings';
import Legitimization from './components/Legitimization';

// Init Common Services
const commonMethods = new CommonMethods();

new Slider(commonMethods);
new ScrollHeader();
new MobileMenu();
new Legitimization(commonMethods);
new TabNav();
new Tooltip(commonMethods);
new ConditionalDataToggle(commonMethods);
new FormHandler(commonMethods);
new Dialog(commonMethods);
new BookingHandler(commonMethods);
