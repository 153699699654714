/**
 * CommonMethods.js
 *
 * @version 1
 * @copyright 2023 SEDA.digital GmbH & Co. KG
 */

'use strict';

import ClassLogger from 'ClassLogger';

class CommonMethods {
    getClassName () { return 'CommonMethods'; }

    /**
     * Create a new instance.
     */
    constructor () {
        /** @type {console} */
        this.logger = ClassLogger(this, true);
    }

    /**
     * Returns a promise that resolves once the DOM is ready
     * this is useful for async loaded scripts
     * @returns Promise
     */
    async isDomReady () {
        return new Promise((resolve, reject) => {
            if (document.readyState === 'interactive' || document.readyState === 'complete') {
                resolve();
                return;
            }

            document.addEventListener('DOMContentLoaded', () => {
                resolve();
            });
        });
    }

    debounce (func, wait = 300, immediate = false) {
        const self = this;
        if (!self.debounceTimers) {
            self.debounceTimers = {};
        }
        return function () {
            const context = this;
            const args = arguments;
            const later = function () {
                self.debounceTimers[func] = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !self.debounceTimers[func];
            if (self.debounceTimers[func]) clearTimeout(self.debounceTimers[func]);
            self.debounceTimers[func] = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    markupToElement (markup) {
        const template = document.createElement('template');
        template.innerHTML = markup.trim();
        return template.content.firstChild;
    }

    /**
     *
     * @param {string} iconName
     * @param {string} classes
     * @param {string} attributes
     */
    getSvgIcon (iconName, classes = '', attributes = '') {
        return `
        <svg role="presentation" class="${classes}" ${attributes}>
            <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/dist/websites/sprite.svg#${iconName}"
                href="/dist/websites/sprite.svg#${iconName}"
            ></use>
        </svg>
    `.trim();
    }
}

export default CommonMethods;
