'use strict';

import ClassLogger from 'ClassLogger';

export default class MobileMenu {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'MobileMenu';
    }

    constructor () {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.init();
    }

    /**
     * Initialize
     *
     * @returns {this}
     */
    init () {
        this.mobileBtn = document.querySelector('.c-mobilemenu-btn');
        if (!this.mobileBtn) {
            return;
        }
        this.mainHeader = this.mobileBtn.closest('.l-page-header');
        if (!this.mainHeader) {
            this.logger.log('Did not find a header');
            return;
        }

        this.isVisible = false;

        this.mobileBtn.addEventListener('click', (e) => {
            e.preventDefault();
            if (this.isVisible) {
                this.mainHeader.classList.remove('is-expanded');
                this.isVisible = false;
                this.mobileBtn.classList.remove('is-open');
            } else {
                this.isVisible = true;
                this.mainHeader.classList.add('is-expanded');
                this.mobileBtn.classList.add('is-open');
            }
        });
    }
}
