'use strict';

import ClassLogger from 'ClassLogger';

export default class Legitimization {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'Legitimization';
    }

    constructor (commonMethods) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;

        this.commonMethods.isDomReady().then(() => {
            this.init();
        });
    }

    /**
     * Initialize this component.
     *
     * @returns {this}
     */
    init () {
        /** @type {NodeListOf<HTMLInputElement>} */
        const legitimizationUploadInputs = document.querySelectorAll('input[type=file]');

        legitimizationUploadInputs.forEach(input => {
            input.addEventListener('change', event => this.showFilename(event));
        });
    }

    /**
     * Renders messages from api call
     *
     * @param {Event} event
     */
    showFilename (event) {
        const target = event.target;

        if (target == null) {
            this.logger.log('Failed to retrieve event target from file upload change event', {
                event,
            });
            return;
        }

        /** @type {HTMLInputElement} */
        const parent = target.parentNode;

        if (parent == null) {
            this.logger.log('Failed to retrieve parent of file upload input. Cannot set file name', {
                target,
                parentNode: target.parentNode,
            });
            return;
        }

        const filenameElement = parent.querySelector('[data-legitimization-upload-filename]');

        if (filenameElement == null) {
            this.logger.log('No filename element found, searched for [data-legitimization-upload-filename] on parent', {
                parent,
            });
        }

        const files = event.target.files;

        this.logger.log('Found filename element. Setting file name for legitimization upload file', {
            files,
        });

        const fileName = files[0].name;

        filenameElement.textContent = fileName;
    }
}
