/**
 * Dialog Class
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 *
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 */

'use strict';

import ClassLogger from 'ClassLogger';
import A11yDialog from 'a11y-dialog';

class Dialog {
    getClassName () { return 'Dialog'; }

    /**
     * @param {CommonMethods} commonMethods
     */
    constructor (commonMethods) {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;

        this.commonMethods.isDomReady().then(() => {
            this.init();
        });
    }

    init () {
        const dialogNodes = document.querySelectorAll('.c-dialog');

        if (dialogNodes.length === 0) {
            return;
        }

        dialogNodes.forEach(dialogNode => {
            this.initDialogNode(dialogNode);
        });
    }

    initDialogNode (dialogNode) {
        if (dialogNode.classList.contains('is-initialized')) {
            return;
        }

        const dialog = new A11yDialog(dialogNode);

        const lastFocus = document.activeElement;
        dialog.on('show', function (event) {
            const container = event.target;
            container.setAttribute('aria-hidden', false);
            container.focus();
        }).on('hide', function (event) {
            const container = event.target;
            container.setAttribute('aria-hidden', true);
            if (lastFocus) {
                lastFocus.focus();
            } else {
                container.blur();
            }
        });

        dialogNode.classList.add('is-initialized');
        this.logger.log('Initialized dialog', { dialogNode });

        if (dialogNode.dataset.autoopen === 'true') {
            this.logger.log('Automatically opening modal', { dialogNode });
            dialog.show();
        }

        return dialog;
    }
}

export default Dialog;
