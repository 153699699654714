'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 */

import ClassLogger from 'ClassLogger';

export default class FormHandler {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'FormHandler';
    }

    /**
     * Create a new instance.
     *
     * @param {CommonMethods} commonMethods
     */
    constructor (commonMethods) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.submitted = false;

        this.commonMethods.isDomReady().then(() => {
            this.init();
        });
    }

    /**
     * Initialize form handling.
     *
     * @returns {this}
     */
    init () {
        const forms = document.querySelectorAll('[data-check-submit]');
        forms.forEach(form => {
            this.checkSubmit(form);
        });
    }

    /**
     * We disable all submit buttons on the page, when a user clicks on the submit button of a form on the page.
     * @param {HTMLElement} form
     */
    checkSubmit (form) {
        form.addEventListener('submit', (e) => {
            if (this.submitted === true) {
                return false;
            }
            this.submitted = true;
            const submitButtons = document.querySelectorAll('form [type="submit"]');
            let i;
            for (i = 0; i < submitButtons.length; ++i) {
                submitButtons[i].innerHTML = 'Bitte warten...';
                if (submitButtons[i].classList) submitButtons[i].classList.add('disabled');
            }
            return true;
        });
    }
}
