'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 */

import ClassLogger from 'ClassLogger';

export default class ConditionalDataToggle {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'ConditionalDataToggle';
    }

    /**
     * Create a new instance.
     *
     * @param {CommonMethods} commonMethods
     */
    constructor (commonMethods) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;

        this.conditionallyShownElements = [];

        this.commonMethods.isDomReady().then(() => {
            this.init();
        });
    }

    /**
     * Initialize the conditional rendering.
     *
     * @returns {this}
     */
    init () {
        this.logger.log('Searching for DOM elements having a "data-show-if" attribute');

        this.conditionallyShownElements = Array.from(
            document.querySelectorAll('[data-show-if]'),
        );

        if (this.conditionallyShownElements.length === 0) {
            this.logger.log('No elements with "data-show-if" attribute found. Stopping here');
            return;
        }

        this.conditionallyShownElements.forEach(element => {
            this.handleConditionallyShownElement(element);
        });
    }

    /**
     * Conditionally show or hide the given element (method argument) depending
     * on whether other DOM elements are active or inactive.
     *
     * @param {Element} affectedElement
     */
    handleConditionallyShownElement (affectedElement) {
        const condition = String(affectedElement.dataset.showIf || '');

        /**
         * We’re only supporting the "eqeqeq" condition for now because it’s the only
         * use case. We may add support for other comparisons like `!==` in the
         * future. Fow now, we’re fine with this reduced set of functionality.
         */
        const [elementName, value] = condition.split('===');

        const selector = `[name^="${elementName}"]`;
        const triggerElements = document.querySelectorAll(selector);

        if (triggerElements.length === 0) {
            this.logger.log('No elements found triggering a state change of the element with "data-show-if" assigned', {
                elementName,
                affectedElement,
                selectorUsedInQuerySelectorAll: selector,
            });
        }

        this.logger.log('Found trigger elements for the selector from "data-show-if"', {
            triggerElements,
            affectedElement,
        });

        triggerElements.forEach(triggerElement => {
            // Initial rendering happens during MODX chunk rendering
            // re-render when trigger element is clicked
            triggerElement.addEventListener('click', () => {
                triggerElement.value === value
                    ? affectedElement.classList.remove('u-hide')
                    : affectedElement.classList.add('u-hide');
            });
        });
    }
}
